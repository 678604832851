<template>
    <div id="features">
      <div class="container-fluid">
        <div class="add-section" v-if="canAccess(['store-landmarks', 'update-landmarks'])">
          <b-modal id="confirm-print-invocie-modal" hide-header-close @hidden="closeModal" :cancel-title="$t('close')"
            ok-title="Print Invoice" title="Confirm Print Invocie" @ok="generatePDF">
            <b-form class="d-grid grid-cols-1 gap-5">
              <h3>Print Invoice</h3>
              <p>
                Hi dear accountant, you are about to print the invoice for the hotel. Please confirm to proceed.
              </p>
            </b-form>
          </b-modal>
        </div>
        <!-- Tabel Section -->
        <div class="table-sections">
          <iq-card style="padding: 5rem 0 5rem 0;">
            <!-- print pdf -->
            <div >
              <div ref="pdfContent" id="pdfContent" class="pdf-container hidden-content">
                <div class="tb-container">
                  <b-container class="bv-example-row">
                    <div class="line"></div>
                    <b-row align-h="between" class="header">
                      <b-col class="header-logo">
                        <img src="../../../public/website_logo.jpeg" height="120" width="150" alt="">
                      </b-col>
                      <b-col class="header-logo">
                        <!-- <img style="float: right;" :src="imageUrl()" height="120" width="150" alt=""  @load="imageLoaded = true"> -->
                      </b-col>
                    </b-row>
                    <h4 style="font-weight: bold;">
                      Statement of Account
                    </h4>
                    <div class="line-header"></div>
                    <b-row>
                      <b-col cols="4"">
                  <p style=" color: black;" dir="ltr"><b> Account Name :</b> <span class="border-left-color"> {{
                    invoice.data.hotel.title }} </span></p>
                      </b-col>
                      <b-col cols="4">
                        <p style="color: black;" dir="ltr"><b> Governorate :</b> <span class="border-left-color"> {{
                          invoice.data.hotel.governorate?invoice.data.hotel.governorate.en_name:null }} </span></p>
                      </b-col>
                      <b-col cols="4">
                        <p style="color: black;" dir="ltr"><b> Created Date :</b> <span class="border-left-color"> {{
                          invoice.data.hotel.created_at }} </span></p>
                      </b-col>
                      <b-col cols="4">
                        <p style="color: black;" dir="ltr"><b> Account Code :</b> <span class="border-left-color"> {{
                          invoice.data.hotel.code }}
                          </span></p>
                      </b-col>
                      <b-col cols="4">
                        <p style="color: black;" dir="ltr"><b> Invoice No. :</b> <span class="border-left-color"> {{
                          invoice.data?invoice.data.invoice_no:'' }}
                          </span></p>
                      </b-col>
                      <b-col cols="4">
                        <p style="color: black;" dir="ltr"><b> Issue Date :</b> <span class="border-left-color"> {{
                          invoice.data.created_at
                            }}
                          </span></p>
                      </b-col>
                    </b-row>
                    <b-row>
                      <div class="tb-reservations">
                        <table>
                          <tr>
                            <th v-for="header in printFields">{{ header.label }}</th>
                          </tr>
                          <tr v-for="resItem in invoice.data.reservations">
                            <td>{{ resItem.created_at }}</td>
                            <td>{{ resItem.full_name }}</td>
                            <td>{{ resItem.phone }}</td>
                            <td>{{ resItem.reservation_no }}</td>
                            <td class="total-price-tb"> $ {{ resItem.total_price }}</td>
                          </tr>
                          <tr class="total-row">
                            <td colspan="4"> Total Reservations Price</td>
                            <td class="total-price-tb bg-price-hotel"> $ {{ totalPriceSum }}</td>
                          </tr>
                          <tr class="total-row">
                            <td colspan="4"> Total Price After Tax %13</td>
                            <td class="total-price-tb bg-price"> $ {{ Math.round(totalPriceSum - (totalPriceSum * 0.13))  }}</td>
                          </tr>
                        </table>
                      </div>
                    </b-row>
                  </b-container>
                </div>
              </div>
            </div>
            <template v-slot:cell(icon)="data">
              <i :class="data.item.icon"></i>
            </template>
            <template v-slot:headerTitle>
              <b-button variant="success" @click="confirmPrintInvoice">Generate Invoice</b-button>
            </template>
          </iq-card>
        </div>
      </div>
    </div>
  </template>
  <script>
  import jsPDF from 'jspdf'
  import html2canvas from 'html2canvas'
  export default {
    name: 'Hotel-Reservation',
    data() {
      return {
        filterData: {
          page: 1,
          full_name: '',
          reservation_no: '',
          phone: '',
          id: this.$route.params.id,
          kr_name: ''
        },
        invoice_no: '',
        payload: {
          kr_name: '',
          feature_id: ''
        },
        imageLoaded: false,
        total_price: 0,
        showFeatures: {},
        items: [],
        reservationList: [],
        icons: [
          {
            icon: 'fas fa-utensils',
            title: 'Food'
          },
          {
            icon: 'fas fa-glass-martini-alt',
            title: 'Bar'
          },
          {
            icon: 'fas fa-dumbbell',
            title: 'Gym'
          },
          {
            icon: 'fas fa-swimmer',
            title: 'Pool'
          },
          {
            icon: 'fas fa-hot-tub',
            title: 'Sauna'
          },
          {
            icon: 'fas fa-concierge-bell',
            title: 'Room Service'
          },
          {
            icon: 'fas fa-gamepad',
            title: 'Game Room'
          },
          {
            icon: 'fas fa-parking',
            title: 'Parking'
          }
        ]
      }
    },
    computed: {
      fields() {
        return [
          {
            label: this.$t('Date'),
            key: 'created_at'
          },
          {
            label: "Client Name",
            key: 'full_name'
          },
          {
            label: "Phone",
            key: 'phone'
          },
          {
            label: "Reservation No.",
            key: 'reservation_no'
          },
          {
            label: "Total Amount",
            key: 'total_price'
          },
        ]
      },
      totalPriceSum() {
        this.invoice
        console.log(this.invoice.data)
        return this.invoice.data?this.invoice.data.reservations.reduce((sum, resItem) => sum + parseFloat(resItem.total_price), 0): 0;
      },
      printFields() {
        return [
          {
            label: this.$t('Date'),
            key: 'created_at'
          },
          {
            label: "Client Name",
            key: 'full_name'
          },
          {
            label: "Phone",
            key: 'phone'
          },
          {
            label: "Reservation No.",
            key: 'reservation_no'
          },
          {
            label: "Total Amount",
            key: 'total_price'
          },
        ]
      }
    },
    methods: {
      confirmPrintInvoice() {
        this.$bvModal.show('confirm-print-invocie-modal')
      },
      async generatePDF() {
        const element = this.$refs.pdfContent // Select the element to convert to PDF
        try {
          const canvas = await html2canvas(element, { scale: 2 }) // Increase scale for better resolution
          const imgData = canvas.toDataURL('image/png')
          const pdf = new jsPDF('p', 'mm', 'a4')
          const imgWidth = 210 // A4 width in mm
          const imgHeight = (canvas.height * imgWidth) / canvas.width
          useCORS: true,
          pdf.addImage(imgData, 'PNG', 0, 10, imgWidth, imgHeight)
          pdf.save('INV'+ this.invoice.data.invoice_no + '.pdf') // Save the PDF
        } catch (error) {
          console.error('Error generating PDF:', error)
        }
      },
      imageUrl() {
        return this.hotel.image;
      },
      closeModal(bvModalEvent) {
        bvModalEvent.preventDefault()
        this.payload = this.resetObject(this.payload)
      }
    },
    watch: {},
    mounted() {
      this.authUserPermissions();
      this.getHotelInvoice(this.$route.params.id)
    }
  }
  </script>
  <style scoped>
  .tb-reservations {
    margin-left: 15px;
  }
  
  .tb-container {
    margin: 0 30px 0 30px;
    width: 90%;
  }
  
  .content {
    width: 100%;
    font-size: 14px;
    text-align: right;
    line-height: 5px;
  }
  
  .company-info {
    margin-top: 10px;
  }
  
  .line {
    width: 100%;
    height: 5px;
    background-color: #013b7d;
    margin: 20px 0;
  }
  
  .line-header {
    width: 100%;
    height: 1px;
    background-color: #013b7d;
    margin: 0 0 20px 0;
  }
  
  .header {
    margin-bottom: 30px;
  }
  
  .border-left-color {
    border-right: 1px solid #fff;
  }
  
  table,
  td,
  th {
    border: 1px solid;
    color: black;
  }
  
  th {
    text-align: center;
  }
  
  td {
    padding-left: 5px;
  }
  
  table {
    width: 1035px;
    border-collapse: collapse;
  }
  
  .total-price-tb {
    text-align: center;
  }
  
  .header-logo {
    height: 7rem;
  }
  
  .total-row {
    background-color: #f0f0f0;
  }
  
  .bg-price {
    background-color: #013b7d;
    color: #fff;
    border: 1px solid #013b7d;
  }
  .bg-price-hotel {
    background-color: #013b7d;
    color: #fff;
    border: 1px solid #013b7d;
    border-bottom: 1px solid #fff;
  }
  </style>
  